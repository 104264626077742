body {
  background-color: #e9ebf0;
  font-family: Helvetica, Arial;
}

.header {
  background-color: #0093d5;
  padding: 10px;
}
.header h1 {
  color: #fff;
  text-align: center;
}

.wrapper {
  margin-top: 50px;
  background-color: #fff;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
}
.wrapper p {
  font-style: italic;
  color: #666;
}

.wrapper label {
  display: block;
}
.wrapper input {
  padding: 3px 6px;
}
.amount-input {
  margin-bottom: 10px;
}
.wrapper select {
  display: block;
}

.wrapper hr {
  margin: 40px auto;
}

.wrapper {
  width: 50%;
}


.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
