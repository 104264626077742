ul {
  margin-bottom: 0rem !important;
  color: rgb(0, 33, 65);
}

.nav {
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8vh;
  background: rgb(0, 33, 65);
  margin: 0;
  box-sizing: border-box;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 100;
}

.nav-text {
  font-family: "Roboto Slab", serif;
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}

.logo-icon {
  width: 13%;
  height: auto;
  padding-left: 5px;
}

.nav-brand {
  font-size: 2rem;
  white-space: nowrap;
  font-weight: bold !important;
}

.nav-item {
  font-size: 1.6rem;
  margin-bottom: 15px;
  white-space: nowrap;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
  z-index: 100;
}

.nav-toggler {
  display: none;
}

.nav-toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav-toggler {
    display: block;
    cursor: pointer;
  }
  .nav-menu {
    position: fixed;
    top: 7vh;
    right: 0;
    height: 93vh;
    width: 50vw;
    background: rgb(0, 33, 65);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
  }
}

.nav-active {
  transform: translateX(0%);
  z-index: 100;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 9px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -9px);
}

.sign-in-button {
  color: white;
  font-family: "Roboto Slab", serif;
  background-color: rgb(0, 33, 65) !important;
  border-color: white !important;
  width: 6vw;
  height: 5vh;
  min-width: 70px;
  min-height: 34px;
  white-space: nowrap;
}

.sign-in-button:hover {
  background-color: rgb(1, 74, 148) !important;
}

.vertical-line {
  border-right: 1px solid #fff;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 30px;
  margin-bottom: 12px;
}

.nav-item:hover .dropdown-menu {
  display: block;
  border: 2px solid white;
  border-top: 0;
  outline: 2px solid rgb(0, 33, 65);
}

.dropdown-menu {
  position: absolute;
  z-index: 1;
  display: none;
  background-color: rgb(0, 33, 65);
}

.dropdown-menu a {
  display: block;
  color: white;
  text-align: left;
  padding: 0.5vh 1vw;
}

.dropdown-menu a:hover {
  background-color: #00356d;
}
