.wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 0.3vh groove rgb(0, 33, 65);
    padding-bottom: 5vh;
}

.wrapper-content {
    padding-top: 2vh;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statistics-wrapper-height {
    min-height: 100vh;
}

/* source: https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile */
@media (max-width: 805px) {
    .statistics-wrapper-height {
        min-width: 100vw;
    }
}


.header {
    color: white;
    font-family: "Roboto Slab", serif;
    background-color: rgb(0, 33, 65);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    border-radius: 1vh;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.section-title {
    margin-top: 0vh;
    margin-bottom: 1vh;
    font-size: 2vw !important;
}

@media (max-width: 805px) {
    .section-title {
        font-size: 4vw !important;
    }
  }

hr {
    width: 100%;
}

.calender-row {
    display: flex;
}

.get-statistics-button {
    color: white !important;
    padding: 22px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    margin: 1vw !important;
    background-color: rgb(0, 33, 65) !important;
}

.get-statistics-button:hover {
    background-color: rgb(35, 80, 128) !important;
}