.flex-parent-element {
    display: flex;
    width: 100%;
}

@media (max-width: 803px) {
    .flex-parent-element {
      flex-direction: column;
    }
  }

.flex-child-element {
    flex: 1;
    margin: 3vh;
    margin-bottom: 0;
    height: 62vh;
}

.flex-child-body {
    border: 0.3vh groove rgb(0, 33, 65);
    background-color: white;
    border-radius: 1vh;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 65vh;
}

@media (max-width: 803px) {
    .record-transaction-resize {
        height: 55vh;
    }
}

@media (max-width: 803px) {
    .offer-transaction-resize {
        height: 65vh;
    }
}

.my-exchanges-instructions {
    font-family: "Roboto Slab", serif;
    color: #545353;
    margin: 2vw;
}

.center-record-component {
    padding-bottom: 3.3vw !important;
}