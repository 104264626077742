.currency-row {
    margin: 1vh;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.menu-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 803px) {
    .button-row {
        padding-top: 2vh;
    }
  }

.currency-label {
    display: inline-block;
    width: 8vw;
    white-space: nowrap;
}

@media (max-width: 803px) {
    .currency-label {
        margin-right: 7vw !important;
    }
  }

.amount-textfield {
    width: 15vw !important;
    margin-right: 8vw !important;
}

@media (max-width: 803px) {
    .amount-textfield {
        width: 50vw !important;
    }
  }

.record-transaction-button {
    background-color: rgb(0, 33, 65) !important;
    margin: 1vw !important;
}

.record-transaction-button:hover {
    background-color: rgb(35, 80, 128) !important;
}