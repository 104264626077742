h2 {
    font-family: "Roboto Slab", serif;
}

.rate-card{
    width: 13vw;
    min-width: 5vw;
    height: auto;
}

@media (max-width: 805px) {
    .rate-card {
        min-width: 30vw;
        min-height: auto;
    }
}

.home-card-title {
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 33, 65);
    border: 2px groove rgb(0, 33, 65);
    border-radius: 1vh;
    margin: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 5vh;
    white-space: nowrap;
    font-size: 2.7vw;
}

.home-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 1vh;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    border: 0.3vh groove rgb(0, 33, 65);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.home-card-rates-title {
    width: 60vw;
    min-width: 53vw;
    height: 7vh;
}

@media (max-width: 805px) {
    .home-card-rates-title {
        min-width: 100vw;
    }
}

.home-card-rates {
    width: 60vw;
    min-width: 53vw;
    height: 28vh;
}

@media (max-width: 805px) {
    .home-card-rates {
        min-width: 100vw;
    }
}

.home-card-converter-title {
    width: 63vw;
    height: 7vh;
}

@media (max-width: 805px) {
    .home-card-converter-title {
        min-width: 100vw;
    }
}

.home-card-converter {
    width: 63vw;
    height: 30vh;
}

@media (max-width: 805px) {
    .home-card-converter {
        min-width: 100vw;
    }
}