.form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vh;
}
  
.input-container {
    display: flex;
    align-items: center;
    gap: 1vh;
    padding: 5px;
}
  
.textfield-and-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5vh;
}

.request-textfield {
    margin-right: 0.5vw;
  }

.request-label {
    margin-right: 1.5vw !important;
    white-space: nowrap;
}

.request-other-currency {
    color: #666666;
    margin-top: 2vh !important;
}

.container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

.offer-currency-menu {
    margin-left: 3.2vw !important;
}