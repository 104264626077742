.switch-conversion-button {
    margin-left: 8px !important;
    margin-top: 50px !important;
    width: 64px;
    height: 36px;
    color: rgb(0, 33, 65) !important;
    border-color: rgb(0, 33, 65) !important;
}

.switch-conversion-button:hover {
    background-color: rgb(35, 80, 128) !important;
    border-color: rgb(35, 80, 128) !important;

    color: white !important;

}

.convert-button {
    background-color: rgb(0, 33, 65) !important;
}

.convert-button:hover {
    background-color: rgb(35, 80, 128) !important;
}

.flag-converter {
    width: 25px;
    height: auto;
    margin-left: 5px;
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: middle;
}

.currency-label {
    font-size: 20px !important;
    font-family: "Roboto Slab", serif !important;
    white-space: nowrap;
}

.amount-textfield {
    min-width: 20vw !important;
 }

 @media (max-width: 803px) {
    .amount-textfield {
        max-width: 35vw;
     }
}

.amount-textfield input {
    background-color: white;
    font-family: "Roboto Slab", serif;
}