.rate-card {
  /* width: 13vw;
  height: 8vw; */
  border: 2px groove rgb(0, 33, 65);
  border-radius: 30px;
  box-shadow: 0 2px 4px rgb(0, 33, 65);
  background-color: white;
  display: inline-block;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  user-select: none;
}

.rate-card:hover {
  transform: scale3d(1.05, 1.05, 1.05);
}

h2 {
  font-size: 2.5vw !important;
  text-align: center;
}

@media (max-width: 805px) {
  h2 {
    font-size: 5.7vw !important;
    text-align: center;
  }
}

p {
  margin-top: 3vh;
  margin-bottom: 0;
}

h2.remove-whitespace {
  margin: 0;
  padding: 0;
}

.rates-card-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
}

.rates-card-body {
  font-size: 2vw !important;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  color: #666;
}

@media (max-width: 805px) {
  .rates-card-body {
    font-size: 5vw !important;
    text-align: center;
  }
}

.flag {
  width: 13%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.flag-left {
  margin-left: 12%;
}

.flag-right {
  margin-left: 50%;
}

.currency {
  font-size: 2.4vw;
}