.percent-change-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.percent-change {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
}

.positive {
    color: green;
}

.negative {
    color: red;
}

.zero {
    color: blue;
}

h3 {
    font-family: "Roboto Slab", serif;
}